import { Component, OnInit, ViewChild, HostListener} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { RadarProdutoGeradoService } from 'apps/public/src/app/shared/services/radar-produto-gerado.service';
import { ActivatedRoute } from '@angular/router';
import { MapCoreComponent } from '../../shared/components/map/map-core/map-core.component';
import { GlobalVars } from '../../shared/global';
import { Router } from '@angular/router';
import { MatButtonToggleModule, MatSelectModule } from '@angular/material';
import { formatDate, Location } from '@angular/common';
import { MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavigationComponent } from '../../layout/navigation/navigation.component';
import { transform } from 'ol/proj';


import Swal from 'sweetalert2';

import moment from 'moment';


export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-radar',
  templateUrl: './radar.component.html',
  styleUrls: ['./radar.component.css'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})


export class RadarComponent implements OnInit {
  showTestAlert(resp) {
    Swal.fire({
      title: "Aviso",
      html: resp,
      icon: "warning",
      confirmButtonText: "Ok"
    });
  };

  public mapOptions: any;
  private mapLayers;
  private dates;
  private circle_kml;
  private markers = [];
  private marker = [];
  private locationLayers = [];
  private layersVector = [];
  private zoomChangeTimeout: any;

  public running = true;
  public loading = true;
  public control;
  private count;
  private lastCount;

  public sourceValue = 'radar';
  public radarLocal = 'fortaleza';
  public dataCadastro;
  public dataCadastros;
  public displayPaletaDesktop = true;
  public isCheckboxDisable = "false";
  public isCheckDisable = "false"
 

  public radar;
  public produto;
  public camada;
  public layout;
  public address = '';

  public currentKML;
  public popup: boolean = false;
  public currentPosition: boolean = false;
  public showCurrentPosition: boolean = true;
  public interval;
  public buttonStyle: string = '';
  public produtoLegendaStyle: string = 'produto-legenda-vertical'
  private sessionMarkers = [];
  private sessionCenter = [];
  private sessionZoom = [];

  public selectQuantidadeImagens = "20";
  public selectVelocidade = "2";

  //public defaultDate = new Date();
  public paramDate = this.activatedroute.snapshot.paramMap.get('data').replace('-','/').replace('-','/');
  public defaultDate = (this.paramDate != '0') ? new Date(this.paramDate) : new Date();

  public defaultDateFormated = formatDate(this.defaultDate, 'dd/MM/yyyy', 'en-US');
  public defaultDateFormatedToDate = typeof(Date.parse(this.defaultDateFormated));
  public showProgressBar = false;

  public mobileTaskMenu: string = '';
  public lat = 0;
  public long = 0;
  public zoom = 0;
  public mapFullScreen = false
  public mapFullScreenButton = false

  public dicionarioDeRadares =
    {
      "DWSR92X": {
        nome: "Fortaleza",
        mapOptions: {
          center: [-38.557581, -3.794561],
          zoom: 8.5
        },
        bound: [-39.6355, -4.8726, -37.4796, -2.7166]
      },
      "RMT0100DS": {
        nome: "Quixeramobim",
        mapOptions: {
          center: [-39.4, -5.09],
          zoom: 6.8
        },
        bound: [-42.6806, -8.4827, -35.8536, -1.6557]
      }
  };

  public dicionarioDeVelocidade =
  {
    "1" : 1200,
    "2" : 700,
    "3" : 300
  }

  public dicionarioDeCamadas =
    {
      "MACRORREGIOES": "https://cdn.funceme.br/assets/kml/macro_regioes_radar.kml",
      "REGIOES_HIDRO": "https://cdn.funceme.br/assets/kml/regioes_hidrograficas.kml",
      "MUNICIPIOS": "https://cdn.funceme.br/assets/kml/municipios_ceara.kml",
      "BAIRROS_FOR": "https://cdn.funceme.br/assets/kml/bairros_fortaleza.kml",
      "SER": "https://cdn.funceme.br/assets/kml/secretaria_executiva_regional.kml" ,
      "RADAR_FORTALEZA" : "https://cdn.funceme.br/assets/kml/rangeDWSR92X.kml",
      "RADAR_QUIXERAMOBIM" : "https://cdn.funceme.br/assets/kml/rangeRMT0100DS.kml",
      "AREAS_RISCO_FORTALEZA" : "https://cdn.funceme.br/assets/kml/fortaleza_areas_risco.kml"
  }

  public dicionarioDeProdutos =
    {
      "prsf": {
        nome: "Chuva agora",
        legenda: "assets/img/PRSF_Intensidades.png"
      },
      "acum-horario": {
        nome: "Acumulado horário",
        legenda: "assets/img/acum_mm_horario.png"
      },
      "acum-diario": {
        nome: "Acumulado diário",
        legenda: "assets/img/acum_mm_diario.png"
      }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
        },
      );
    }
  }



  @ViewChild('map', { static: true }) map: MapCoreComponent;

  private subscriptions = new Subscription();

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.layoutAdjusts();
  }

  constructor(

    private service: RadarProdutoGeradoService,
    private activatedroute: ActivatedRoute,
    public router: Router,
    public matButton: MatButtonToggleModule,
    public matSelect:MatSelectModule,
    private http: HttpClient) {
      this.subscriptions.add(this.service.serviceListener$.subscribe(
       async (response) => {
          if(response.error == true){
            this.showTestAlert(response.message);
            this.showProgressBar == false;
            this.toggleProgressBar();
          }
          // this.showTestAlert("Prezados(as), <br><br>Gostaríamos de informar que, devido a uma manutenção programada e essencial, nossos sistemas poderão apresentar instabilidade no dia 27/03/2024.<br><br>Estamos comprometidos em restaurar nossos serviços o mais rápido possível. Se você tiver alguma dúvida, entre em contato conosco através do telefone (85) 3101.1130.<br><br>Atenciosamente.")

          if (GlobalVars.dateRadar == "0")
          {
            GlobalVars.dateRadar = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
          }

          if (response.data.list[0].result.length > 0)
          {
            this.plot(response.data.list[0]);
          }
          else if (response.data.list[0].result.length === 0 && response.data.list[0].alerta === '')
          {
            this.showTestAlert("Não há dados para este radar na data escolhida!")
          }
          if (response.data.list[0].alerta != '') {
            this.dataCadastro = '';
            if (this.mapLayers != null) {
              this.map.removeImageLayer(this.mapLayers[this.lastCount]);
            }
            this.showTestAlert(response.data.list[0].alerta)
          }
          if (this.showProgressBar == true)
            this.toggleProgressBar();
      },
      ));
      this.layoutAdjusts();
      this.initScreenResolutionListener();
  }

  ngOnInit() {
    sessionStorage.setItem('zoomQ', '6.8');
    sessionStorage.setItem('zoomF', '8.5');
    this.autoRefresh();
    this.layoutAdjusts();
    this.loadMarkersFromSession();
    window.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  initScreenResolutionListener(): void {
    let resizeTimeout: number | undefined;
    window.addEventListener('resize', () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = window.setTimeout(() => {
        this.setMapNormalScreen();
      }, 100)
    });
  }

  handleZoomCenterChange() {
    clearTimeout(this.zoomChangeTimeout);
    this.zoomChangeTimeout = setTimeout(() => {
      sessionStorage.setItem('center', transform(this.map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326'));
      sessionStorage.setItem('zoom', this.map.getView().getZoom());
      this.zoom = this.map.getView().getZoom();
    }, 1000);
  }

  layoutAdjusts(){
    //MOBILE
    if (window.innerWidth < window.innerHeight){
      this.buttonStyle = 'mat-icon-compact-style';
      this.produtoLegendaStyle = 'produto-legenda-vertical';
      this.mobileTaskMenu = 'mobile-task-menu-on';
      this.displayPaletaDesktop = false;
      this.mapFullScreenButton = false;
    }
    //PC
    else {
      this.buttonStyle = '';
      this.produtoLegendaStyle = 'produto-legenda-horizontal';
      this.mobileTaskMenu = 'mobile-task-menu-off';
      this.displayPaletaDesktop = true;
      this.mapFullScreenButton = false;
      this.mapFullScreen = false;
    }
  }

  showLegend()
  {
    return GlobalVars.showLegend;
  }

  async changeLegend()
  {
    this.isCheckboxDisable = "true";
    GlobalVars.showLegend = !GlobalVars.showLegend;
    await this.router.navigate([GlobalVars.getStringRoute()]);
    setTimeout(()=>{this.isCheckboxDisable = "false";}, 500);
  }

  showLocation(){
    return this.showCurrentPosition;
  }

  async changeLocation()
  {
    
    this.isCheckDisable = "true";
    this.showCurrentPosition = !this.showCurrentPosition;
    if(this.showCurrentPosition)
      {            
        this.createLocation();
      }else{
        this.cleanLocation();
      }
    setTimeout(()=>{this.isCheckDisable = "false";}, 500);
  }

  async changeDate(newValue)
  {
    this.toggleProgressBar();
    this.defaultDate = moment(newValue, 'DD/MM/YYYY').toDate();
    GlobalVars.dateRadar = formatDate(this.defaultDate, 'yyyy-MM-dd', 'en-US');
    await this.router.navigate([GlobalVars.getStringRoute()]);
  }

  async changeVelocity(newValue)
  {
    this.toggleProgressBar();
    GlobalVars.velocity = newValue;
    await this.router.navigate([GlobalVars.getStringRoute()]);
  }

  async changeAddress(newValue)
  {
    this.toggleProgressBar();
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${newValue}`;
    this.address = newValue;

    try {
      const data = await this.http.get(url).toPromise();
      if (!(<any>data).length){
        alert('Local sem correspondência.');
        this.toggleProgressBar();
        return;
      }
      var coord = [parseFloat(data[0].lon), parseFloat(data[0].lat)];
      this.map.setCenter(coord);
      //this.map.olMap.getView().setZoom(5);
      this.markers.push(this.map.createImageMarker(null, "assets/img/custom_pos.png", coord, '#FFFFFF', 0.15));

      this.sessionMarkers.push({
        image: "assets/img/custom_pos.png",
        coord: coord,
        color: '#FFFFFF',
        opacity: 0.15
      });
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
    this.toggleProgressBar();
    this.address = '';
    sessionStorage.setItem('sessionMarkers', JSON.stringify(this.sessionMarkers));
  }

  loadMarkersFromSession() {
    const sessionMarkersData = sessionStorage.getItem('sessionMarkers');
    if (sessionMarkersData) {
      this.sessionMarkers = JSON.parse(sessionMarkersData);
      for (const markerData of this.sessionMarkers) {
        this.markers.push(this.map.createImageMarker(null, markerData.image, markerData.coord, markerData.color, markerData.opacity));
      }
    }
  }
  createLocation()
  {
    var coord = [this.long, this.lat];
    this.marker.unshift(this.map.createImageMarker(null, "assets/img/custom_pos_real.png", coord, '#FFFFFF', 0.18));
    this.currentPosition = true;
  }

  cleanLocation()
  {
    this.map.removeImageLayer(this.locationLayers);
    this.marker = [];
    sessionStorage.clear();
  }

  cleanAllAddress(){
    sessionStorage
    this.map.removeImageLayer(this.layersVector);
    this.markers = [];
    sessionStorage.clear();
  }

  autoRefresh() {
    //600000ms = 10min = 1000 * 60 * 10
    setInterval(() => {
      window.location.reload();
    }, 600000);
  }

  setMapFullScreen(){
    this.running = true;
        this.mapFullScreen = true;
        document.getElementById("map").style.height = "100vh";
        document.getElementById("map").style.setProperty("aspect-ratio", `${window.innerWidth} / ${window.innerHeight}`);
        document.getElementById("coord").style.position = 'absolute';
        document.getElementById("coord").style.right = '0';
        document.getElementById("coord").style.bottom = '0';
        sessionStorage.setItem('fullScreen', 'true');
  }

  setMapNormalScreen(){
    this.running = true;
    this.mapFullScreen = false;
    document.getElementById("map").style.height = "70vh";
    document.getElementById("map").style.setProperty("aspect-ratio",`${window.innerWidth *1.367 }/ ${window.innerHeight}`);
    document.getElementById("coord").style.right = "";
    document.getElementById("coord").style.position = '';
    sessionStorage.setItem('fullScreen', 'false');
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.setMapNormalScreen()
    }
  }

  async ngAfterViewInit() {
    await this.getCurrentLocation();

    this.subscriptions.add(this.activatedroute.paramMap.subscribe(params => {
      this.running = true;

      if (this.showProgressBar == false)
        this.toggleProgressBar();

      var legend = params.get('legenda')
      GlobalVars.showLegend = (legend == "true");

      this.selectVelocidade = params.get('velocidade');

      this.radar = params.get('radar')
      if (this.dicionarioDeRadares[this.radar] === undefined) {
        this.radar = "RMT0100DS"
        this.radar = "DWSR92X"
      }
      GlobalVars.currentRadar = this.radar

      this.produto = params.get('produto')
      if (this.dicionarioDeProdutos[this.produto] == undefined) {
        this.produto = 'prsf'
      }
      GlobalVars.currentProduct = this.produto

      this.camada = params.get('camada')
      if (this.dicionarioDeCamadas[this.camada] === undefined) {
        this.camada = "MACRORREGIOES"
      }
      GlobalVars.currentLayer = this.camada

      GlobalVars.currentLayout = params.get('layout');
      GlobalVars.dateRadar = params.get('data')
      this.selectQuantidadeImagens = params.get('imagens');
      if
      (
        this.selectQuantidadeImagens === undefined ||
        isNaN(parseInt(this.selectQuantidadeImagens)) ||
        parseInt(this.selectQuantidadeImagens) < 0 ||
        this.selectQuantidadeImagens > GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].max
      )
      {
        this.selectQuantidadeImagens = GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].max;
      }

      if (this.currentKML != undefined) {
        this.map.removeImageLayer(this.currentKML);
      }

      this.currentKML = this.map.createKMLVectorLayer(this.dicionarioDeCamadas[this.camada], { color: 'black', width: 1 });
      this.map.addImageLayer(this.currentKML);

      this.service.forRadar(this.radar)
        .forProduto(this.produto.replace("-", "/"))
        .inTime(parseInt(this.selectQuantidadeImagens))
        .forData(GlobalVars.dateRadar)
        .get();

      this.mapOptions = this.dicionarioDeRadares[this.radar].mapOptions

      if(sessionStorage.center){
        this.map.setCenter(sessionStorage.center.split(',').map(parseFloat))
        
        if(GlobalVars.currentRadar === "RMT0100DS" && this.zoom == sessionStorage.zoomF){
          this.map.olMap.getView().setZoom(this.mapOptions.zoom);
          this.zoom = this.mapOptions.zoom
        }else if(GlobalVars.currentRadar === "DWSR92X" && this.zoom == sessionStorage.zoomQ){
          this.map.olMap.getView().setZoom(this.mapOptions.zoom);
          this.zoom = this.mapOptions.zoom
        }else{
          this.map.olMap.getView().setZoom(sessionStorage.zoom);
          this.zoom = sessionStorage.zoom
        }
      
      }
      else{
        this.map.setCenter(this.mapOptions.center)
        this.map.olMap.getView().setZoom(this.mapOptions.zoom);
        this.zoom = this.mapOptions.zoom
      }

      this.map.redrawMap();
      this.map.removeImageLayer(this.circle_kml);
      window.clearInterval(this.interval);

      if(sessionStorage.fullScreen === 'true'){
        this.setMapFullScreen()
        }
        else{
        this.setMapNormalScreen()
        }
      // this.map.olMap.default({className: "ol-zoom my-new-class-name"})
    }));

    // Ouvinte para o evento change:resolution
    this.map.getView().on('change:resolution', () => {
      this.handleZoomCenterChange();
    });

    this.map.getView().on('change:center', () => {
      this.handleZoomCenterChange();
    });
  }

  public plot(response) {

    if (this.mapLayers) {
      this.mapLayers.forEach(map => {
        this.map.removeVectorLayer(map);
      });
    }

    let mapas = response.result;
    let path = response.path;

    this.count = mapas.length;
    this.lastCount = 0;

    this.mapLayers = [];
    this.dates = [];

    let bound = this.dicionarioDeRadares[this.radar].bound;

    mapas.forEach(ponto => {

      let date = ponto.dia.split('/');

      if (this.produto == 'acum-diario') {
        date = date[0] + '/' + date[1] + '/';
      }
      else {
        date = date[0] + '/' + date[1] + '/' + date[2] + '/';
      }

      this.mapLayers.push(this.map.createImageLayer(path + date + ponto.img, bound))

      let dataCadastro = new Date(ponto.datahora);

      dataCadastro.setHours(dataCadastro.getHours() - 3)

      this.dates.push(dataCadastro);
    })

    this.interval = window.setInterval(() => {
      this.map.removeImageLayer(this.mapLayers[this.lastCount]);
      this.map.olMap.removeLayer()

      this.count = (this.count - 1 < 0) ? mapas.length - 1 : this.count - 1;
      this.lastCount = this.count;

      this.map.addImageLayer(this.mapLayers[this.count]);
      this.dataCadastro = this.dates[this.count];
      
      if (this.marker.length > 0)
        {
          this.map.removeImageLayer(this.locationLayers);
          this.locationLayers = this.map.createVectorLayer(this.marker)
          this.map.olMap.addLayer(this.locationLayers);
        }
      if (!this.currentPosition && this.long != 0 && this.showCurrentPosition)
      {            
        this.createLocation();
      }

      if (this.markers.length > 0)
      {
          this.map.removeImageLayer(this.layersVector); 
          this.layersVector = this.map.createVectorLayer(this.markers)
          this.map.olMap.addLayer(this.layersVector);
      }

    }, this.dicionarioDeVelocidade[this.selectVelocidade]);
  }

  togglePlay() { this.running = true; }
  togglePause() { this.running = false; }

  toggleControls(event, value) {

    event.source.buttonToggleGroup.value = [];

    if (value == 'play') {
      this.running = true;

      this.interval = window.setInterval(() => {

        this.map.removeImageLayer(this.mapLayers[this.lastCount]);

        this.count = (this.count - 1 < 0) ? this.mapLayers.length - 1 : this.count - 1;
        this.lastCount = this.count;

        this.map.addImageLayer(this.mapLayers[this.count])
        this.dataCadastro = this.dates[this.count];

        if (this.markers.length > 0)
          {
            this.map.removeImageLayer(this.layersVector);
            this.layersVector = this.map.createVectorLayer(this.markers)
            this.map.olMap.addLayer(this.layersVector);
          }
        if (this.marker.length > 0)
          {
            this.map.removeImageLayer(this.locationLayers);
            this.locationLayers = this.map.createVectorLayer(this.marker)
            this.map.olMap.addLayer(this.locationLayers);
          }

    }, this.dicionarioDeVelocidade[this.selectVelocidade]);

    } 
    else if (value == 'full_screen'){
      this.setMapFullScreen()
    }
    else if (value == 'close_fullscreen'){
      this.setMapNormalScreen()
    }
    else {
      this.running = false;
      window.clearInterval(this.interval);

      if (value == 'pause') return;

      this.map.removeImageLayer(this.mapLayers[this.lastCount]);

      switch (value[0]) {
        case "first":
          this.count = this.mapLayers.length - 1;
          this.lastCount = this.count;
          break;
        case "prev":
          this.count = (this.count + 1 > this.mapLayers.length - 1) ? 0 : this.count + 1;
          this.lastCount = this.count;
          break;
        case "next":
          this.count = (this.count - 1 < 0) ? this.mapLayers.length - 1 : this.count - 1;
          this.lastCount = this.count;
          break;
        case "last":
          this.count = 0;
          this.lastCount = this.count;
          break;
        default:
          break;
      }

      this.map.addImageLayer(this.mapLayers[this.count])
      this.dataCadastro = this.dates[this.count];
      if (this.markers.length > 0)
        {
          this.map.removeImageLayer(this.layersVector);
          this.layersVector = this.map.createVectorLayer(this.markers)
          this.map.olMap.addLayer(this.layersVector);
        }
      if (this.marker.length > 0)
        {
          this.map.removeImageLayer(this.locationLayers);
          this.locationLayers = this.map.createVectorLayer(this.marker)
          this.map.olMap.addLayer(this.locationLayers);
        }
    }

  }

  getDateOnMap() {
    if (this.dataCadastro === undefined || this.dataCadastro == '') return '';
    if (this.produto == 'prsf')
    {
      return formatDate(this.dataCadastro, 'dd/MM/yyyy', 'en-US');
    }
    else if (this.produto == 'acum-horario')
    {
      let dataCadastroMais1H = new Date(this.dataCadastro.getTime());
      dataCadastroMais1H.setHours(dataCadastroMais1H.getHours() + 1);
      return `${formatDate(this.dataCadastro, 'dd/MM/yyyy', 'en-US')} ${formatDate(this.dataCadastro, 'HH', 'en-US')}h-\n${formatDate(dataCadastroMais1H, 'dd/MM/yyyy', 'en-US')} ${formatDate(dataCadastroMais1H, 'HH', 'en-US')}h`;
    }
    let dataCadastroMenos1Dia = new Date(this.dataCadastro.getTime());
    dataCadastroMenos1Dia.setDate(dataCadastroMenos1Dia.getDate() - 1);
    return `${formatDate(dataCadastroMenos1Dia, 'dd/MM/yyyy', 'en-US')} 7h-\n${formatDate(this.dataCadastro, 'dd/MM/yyyy', 'en-US')} 7h`;
  }

  async onChangeQuantidadeImanges(value : string)  {
    this.toggleProgressBar();
    var intValue = parseInt(value);
    if (isNaN(intValue) || intValue < 1 || intValue > GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].max){
      this.selectQuantidadeImagens = GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].current;
      return;
    }
    GlobalVars.mapNumberOfImages[GlobalVars.currentProduct].current = parseInt(value);
    await this.router.navigate([GlobalVars.getStringRoute()]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    window.clearInterval(this.interval);
  }

  public toggleProgressBar() {
    this.showProgressBar = !this.showProgressBar;
  }

}
