import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './layout/root/root.component';
import { HomeComponent } from './pages/home/home.component';
import { RadarComponent } from './pages/radar/radar.component';
import { RadarMiniComponent } from './pages/radar-mini/radar-mini.component';
import { SateliteComponent } from './pages/satelite/satelite.component';
import { LoginComponent } from './pages/login/login.component';
import { RootSafComponent } from './layout/root-saf/root-saf.component';
import { AuthGuardService } from 'libs/core-app/src/lib/services/auth/auth-guard.service';
import { AuthComponent } from './pages/auth/auth.component';
import { formatDate } from '@angular/common';
import { GlobalVars } from './shared/global';
import { RadarSafComponent } from './pages/radar-saf/radar-saf.component';

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    children:  [
      {
        path: '',
        redirectTo: 'radar/DWSR92X/prsf/RADAR_FORTALEZA/20/true/2/' + GlobalVars.dateRadar,
        pathMatch: 'full'
      },
      { path: 'home', component: HomeComponent },
      /*{ path: 'radar/:radar/:produto/:camada/:imagens/:velocidade/:data', component: RadarComponent },*/
      { path: 'radar/:radar/:produto/:camada/:imagens/:legenda/:velocidade/:data', component: RadarComponent },
      { path: 'satelite', component: SateliteComponent },
      { path: 'login', component: LoginComponent },
      { path: 'login/:mat/:pw', component: LoginComponent },
      { path: 'auth', component: AuthComponent, canActivate: [AuthGuardService] },
    ]
  },
  {
    path: 'radar-mini',
    component: RadarMiniComponent,
    pathMatch: 'full'
  },
  {
    path: 'saf',
    component: RootSafComponent,
    children:  [
      {
        path: '',
        redirectTo: 'radar/DWSR92X/prsf/RADAR_FORTALEZA/20/true/2/' + GlobalVars.dateRadar,
        pathMatch: 'full'
      },
      { path: 'radar/:radar/:produto/:camada/:imagens/:legenda/:velocidade/:data', component: RadarSafComponent },
      { path: 'satelite', component: SateliteComponent },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule { }
