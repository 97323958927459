import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { RadarProdutoGeradoService } from 'apps/public/src/app/shared/services/radar-produto-gerado.service';
import { MapCoreComponent } from '../../shared/components/map/map-core/map-core.component';
import { MatButtonToggleGroup } from '@angular/material';
import { ConfigFacade } from '@funceme/core-app';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-radar-mini',
  templateUrl: './radar-mini.component.html',
  styleUrls: ['./radar-mini.component.css']
})
export class RadarMiniComponent implements OnInit {
  showTestAlert(resp) {
    Swal.fire({
      title: "Aviso",
      html: resp,
      icon: "warning",
      confirmButtonText: "Ok"
    });
  };

  public mapOptions: any;
  private mapLayers;
  private circle_kml;

  public loading = false;

  public showRadar = true;
  public showSatelite = false;

  public sourceValue = 'radar';
  public radarBanda = 'RMT0100DS';
  public dataCadastro;
  public dataCadastros;

  public popup: boolean = false;

  public interval;

  private fortalezaBound = [-39.6355, -4.8726, -37.4796, -2.7166];
  private quixeramobimBound = [-42.6806, -8.4827, -35.8536, -1.6557];

  mapOptionsFortaleza = {
    center: [-38.557581, -3.794561],
    zoom: 7,
    mini: true
  }

  mapOptionsQuixeramobim = {
    center: [-39.645, -4.880],
    zoom: 6,
    mini: true
  }

  @ViewChild('map', { static: true }) map: MapCoreComponent;
  @ViewChild('sourceButtonToggle', { static: true }) sourceButtonToggle: MatButtonToggleGroup;

  private subscriptions = new Subscription();

  constructor(private service: RadarProdutoGeradoService, private configFacade: ConfigFacade) {
    this.mapOptions = this.mapOptionsFortaleza;

    this.subscriptions.add(this.service.serviceListener$.subscribe(
      async (response) => {
          if(response.error == true){
            this.showTestAlert(response.message);
          }
          if (response.data.list[0].result.length > 0)
            {
              this.plot(response.data.list[0]);
            }
            else if (response.data.list[0].result.length === 0 && response.data.list[0].alerta === '')
            {
              this.showTestAlert("Não há dados para este radar na data escolhida!")
            }
            if (response.data.list[0].alerta != '') {
              this.dataCadastro = '';
              this.showTestAlert(response.data.list[0].alerta)
            }
      }
    ));
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    let macro_regiao_kml = this.map.createKMLVectorLayer('https://cdn.funceme.br/assets/kml/macro_regioes_radar.kml',{color: 'black', width: 1});

    this.map.addImageLayer(macro_regiao_kml);

    this.subscriptions.add(
      combineLatest(
        this.configFacade.citySelected$,
        this.configFacade.sourceSelected$
      ).subscribe(([city, source]) => {

        if (source == 'radar'){
          this.loading = false;

          this.showRadar = true;
          this.showSatelite = false;

          if (city == 'fortaleza'){
            this.radarBanda = 'DWSR92X';

            this.map.setCenter(this.mapOptionsFortaleza.center);
            this.map.olMap.getView().setZoom(this.mapOptionsFortaleza.zoom);

          } else {
            this.radarBanda = 'RMT0100DS';

            this.map.setCenter(this.mapOptionsQuixeramobim.center);
            this.map.olMap.getView().setZoom(this.mapOptionsQuixeramobim.zoom);

          }


          this.service.forRadar(this.radarBanda)
            .forProduto('prsf')
            .inTime(3)
            .get(() => {this.loading = false;});

          this.map.redrawMap();
          this.map.removeImageLayer(this.circle_kml);
          window.clearInterval(this.interval);

        } else {
          window.clearInterval(this.interval);
          this.showSatelite = true;
          this.showRadar = false;
        }
      }));
  }

  public plot(response)
  {
    if (this.mapLayers){
      this.mapLayers.forEach(map => {
        this.map.removeVectorLayer(map);
      });
    }

    let mapas = response.result;
    let path = response.path;

    var count = mapas.length - 1;

    this.mapLayers = [];
    var dates = [];

    let bound;

    if (this.radarBanda == 'DWSR92X'){
      bound = this.fortalezaBound;
    }else {
      bound = this.quixeramobimBound;
    }

    if (mapas.length == 0)
      return

    mapas.forEach(ponto => {

      let date = ponto.dia.split('/');

      date = date[0]+'/'+date[1]+'/'+date[2]+'/';

      this.mapLayers.push(this.map.createImageLayer(path+date+ponto.img,bound))

      let dataCadastro = new Date(ponto.datahora);
      dataCadastro.setHours(dataCadastro.getHours() - 3)

      dates.push(dataCadastro);
    })

    this.interval = window.setInterval(()=>{

      if (count == this.mapLayers.length - 1)
        this.map.removeImageLayer(this.mapLayers[0])
      else
        this.map.removeImageLayer(this.mapLayers[count + 1])

      this.map.addImageLayer(this.mapLayers[count])
      this.dataCadastro = dates[count];

      count = (count-1 < 0) ? mapas.length - 1 : count-1;

    },700);
  }

  info() {
    this.popup = !this.popup
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    window.clearInterval(this.interval);
  }
}
