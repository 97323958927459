import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigFacade } from 'libs/core-app/src/lib/state/config/config.facade';
import { ConfigPeriodo, ConfigProduto, emptyConfig, Config } from 'libs/core-app/src/lib/state/config/config.model';
import { GlobalVars } from '../../shared/global';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  @ViewChild('sidenav',null) sidenav: MatSidenav;

  public fullscreen: boolean = false;
  isRadarFortalezaSelected: boolean = false;
  isRadarQuixeramobimSelected: boolean = false;
  isMACRORREGIOESSelected: boolean = false;
  isREGIOES_HIDROSelected: boolean = false;
  isMUNICIPIOSSelected: boolean = false;
  isBAIRROS_FORSelected: boolean = false;
  isSERSelected: boolean = false;
  isRADAR_FORTALEZASelected: boolean = false;
  isRADAR_QUIXERAMOBIMSelected: boolean = false;
  isAREAS_RISCO_FORTALEZASelected: boolean = false;
  prsfRMT0100DSHighlight: boolean = false;
  acumhorarioRMT0100DSHighlight: boolean = false;
  acumdiarioRMT0100DSHighlight: boolean = false;
  prsfDWSR92XHighlight: boolean = false;
  acumhorarioDWSR92XHighlight: boolean = false;
  acumdiarioDWSR92XHighlight: boolean = false;

  public config: Config;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configFacade: ConfigFacade
  ) {

    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-7298747-3',{
          'page_path': event.urlAfterRedirects
      });
    })

  }

  ngOnInit() {
  }

  ngAfterViewChecked(): void{
    this.ativarCamada(GlobalVars.currentLayer)
    this.ativarProduto(GlobalVars.currentProduct, GlobalVars.currentRadar)
    if(GlobalVars.currentRadar === 'DWSR92X'){
        this.isRadarFortalezaSelected = true;
        this.isRadarQuixeramobimSelected = false;}
    else if(GlobalVars.currentRadar === 'RMT0100DS'){
        this.isRadarFortalezaSelected = false;
        this.isRadarQuixeramobimSelected = true;
    }
}

  close()
  {
    this.sidenav.close();
  }

  link(url) {
    this.close();
    this.router.navigate([url]);
  }

  private navigate()
  {
    // let route = this.config.periodo + '/' + this.config.produto + '/' + this.config.metrica + '/' + this.config.ano;

    // if (this.config.mes)
    //   route += '/' + this.config.mes;

    // if (this.config.dia)
    //   route += '/' + this.config.dia;

    // this.router.navigate([route]);

    // this.config.redirect = false;
    // this.configFacade.save(this.config);
  }

  async linkRadar(radar: string, produto: string)
  {

    GlobalVars.currentRadar = radar;
    GlobalVars.currentProduct = produto;
    this.ativarRadar(radar)
    this.ativarProduto(produto, radar);
    await this.router.navigate([GlobalVars.getStringRoute()]);
  }

  async linkCamada(camada: string)
  {
    GlobalVars.currentLayer = camada;
    this.ativarCamada(camada);
    await this.router.navigate([GlobalVars.getStringRoute()]);
  }

  async ativarCamada(camada: string) {
    const propriedade = `is${camada}Selected`;
    for (const key in this) {
      if (key.startsWith("is") && key.endsWith("Selected")) {
        (this as any)[key] = false;
      }
    }
    if (propriedade in this) {
      (this as any)[propriedade] = true;
    }
  }

  async ativarProduto(produto: string, radar: string) {
    const produtoFormatado = produto.replace("-", "")
    const propriedade = `${produtoFormatado}${radar}Highlight`;
    for (const key in this) {
      if (key.endsWith("Highlight")) {
        (this as any)[key] = false;
      }
    }
    if (propriedade in this) {
      (this as any)[propriedade] = true;
    }
  }

  async ativarRadar(radar: string){
    if(radar === 'DWSR92X'){
        var camada = GlobalVars.currentLayer
        this.isRadarFortalezaSelected = true;
        this.isRadarQuixeramobimSelected = false;
        if (camada === "RADAR_QUIXERAMOBIM"){
            GlobalVars.currentLayer = "RADAR_FORTALEZA"
        }
    }else if(radar === 'RMT0100DS'){
        var camada = GlobalVars.currentLayer
        this.isRadarFortalezaSelected = false;
        this.isRadarQuixeramobimSelected = true;
        if (camada === "RADAR_FORTALEZA"){
            GlobalVars.currentLayer = "RADAR_QUIXERAMOBIM"
        }
    }
}
}


