import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import Map              from 'ol/Map';
import OSM              from 'ol/source/OSM';
import Overlay          from 'ol/Overlay';
import XYZ              from 'ol/source/XYZ';
import View             from 'ol/View';
import Projection       from 'ol/proj/Projection';
import StyleIcon        from 'ol/style/Icon';
import KML              from 'ol/format/KML.js';
import {defaults as defaultControls, Attribution} from 'ol/control.js';
import Feature          from 'ol/Feature';
import Point from 'ol/geom/Point.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import VectorSource from 'ol/source/Vector.js';
import Static from 'ol/source/ImageStatic';
import { Icon, Stroke, Style, Text, Fill } from 'ol/style.js';
import {fromLonLat, transform } from 'ol/proj.js';
import { applyTransform } from 'ol/extent';
import { getTransform } from 'ol/proj';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-map-core',
    templateUrl: './map-core.component.html',
    styleUrls: ['./map-core.component.css']
})

export class MapCoreComponent implements OnInit {

    public olMap: any;
    public popupOverlay: Overlay;
    public fullscreen: boolean = false;
    public isMiniApp: boolean = false;
    public isMapCore: boolean = false;
    public layout;
    public mousePosition: String = '0.000, 0.000';

    private circleLayer = null;

    @Input() options;
    @ViewChild('popup', null) popup: ElementRef;
    @ViewChild('coord', null) coord: ElementRef;
    constructor(protected coreRouter: Router, protected coreActivatedRoute: ActivatedRoute)
    { 
    }

    ngAfterViewInit(): void {
        
    }

    getMap()
    {
        return this.olMap;
    }

    redrawMap()
    {
        let _map = this.getMap();
        let interval = window.setInterval(function() {
            try {
            _map.updateSize(interval);
            this.clearInterval();
            } catch (e) {

            }
        }, 100);
    }

    ngOnInit()
    {
        this.init();
        this.layout = this.coreActivatedRoute.snapshot.paramMap.get('layout');
        if(this.layout == 'core'){
            this.isMapCore = true;
        }
    }

    private init()
    {
        this.parseOptions();

        const attribution = new Attribution({
            collapsible: false
        });

        this.olMap = new Map({
            target: this.options.target,
            layers: [
                new TileLayer({
                    source: this.options.source
                }),
                new VectorLayer({
                    source: new VectorSource({
                      //url: 'http://f3.funceme.br:9000/assets/kml/brasil_estados.kml',
                      url: 'https://cdn.funceme.br/assets/kml/brasil_estados.kml',
                      format: new KML({
                        extractStyles: false
                      })
                    }),
                    style: new Style({
                        stroke: new Stroke({color: 'gray', width: 1})
                    })
                })
            ],
            loadTilesWhileAnimating: true,
            //overlays: [this.overlay],
            view: new View({
                center: fromLonLat(this.options.center),
                zoom: this.options.zoom,
                extent: [-4751182.658751392, -947758.5349237248, -3991204.495105713, -184337.33816909065],
                minZoom: this.options.minZoom,
                maxZoom: 19
            }),
            controls: defaultControls({
                attribution: false
            }).extend([attribution])
        });

        this.popupOverlay = new Overlay({
            element: this.popup.nativeElement,
            offset: [9, 9]
        });

        this.olMap.addOverlay(this.popupOverlay);

        this.redrawMap();
        this.addEvents();
        return this;
    }

    public createImageLayer(url, bound){

        let image = new ImageLayer({
            opacity: 0.75,
            source: new Static({
                url: url,
                imageSize: [1364, 1364],
                //imageSize: [2728, 2728],
                projection: this.olMap.getView().getProjection(),
                // imageExtent: fromLonLat(this.options.center),
                imageExtent: applyTransform(bound, getTransform("EPSG:4326", "EPSG:3857"))
            })
        })


        return image;
    }

    public addImageLayer(imageLayer){
        this.olMap.addLayer(imageLayer);
    }

    public removeImageLayer(imageLayer) {
        this.olMap.removeLayer(imageLayer);
    }

    public changeZoomInit()
    {
        this.olMap.getView().setCenter([-39, -5]);
    }

    public setCenter(center)
    {
        this.olMap.getView().setCenter(fromLonLat(center));
    }

    private parseOptions()
    {
        if (!this.options)
            this.options = {};

        this.options['center']  = (this.options.hasOwnProperty('center'))    ? this.options.center    : [0, 0];
        this.options['target']  = (this.options.hasOwnProperty('target'))    ? this.options.target    : 'map';
        this.options['source']  = (this.options.hasOwnProperty('source'))    ? this.options.source    : this.getDefautSourceMap();
        this.options['zoom']    = (this.options.hasOwnProperty('zoom'))      ? this.options.zoom      : this.getInitialMaxZoom(this.options['target']);
        this.options['minZoom'] = (this.options.hasOwnProperty('minZoom'))   ? this.options.minZoom   : this.getMinZoom(this.options['target']);
        this.isMiniApp = (this.options.hasOwnProperty('mini')) ? this.options.mini : false;
    }

    private getDefautSourceMap()
    {
        return new XYZ({
            attributions: [`<a href="http://www.openmaptiles.org/" target="_blank">&copy; OpenMapTiles</a>`,
                `<a href="http://www.openstreetmap.org/about/" target="_blank">&copy; OpenStreetMap contributors</a>`],
            attributionsCollapsible: false,
            url: 'http://tiles.funceme.br/styles/klokantech-basic/{z}/{x}/{y}.png',
            maxZoom: 18
        });
        // return new OSM();
    }

    private getSatteliteSourceMap()
    {
        return new XYZ({
            attributions: ['Powered by Esri',
                           'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
            attributionsCollapsible: false,
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 18
        });
    }

    public setMapSource() {
        this.olMap.getLayers().getArray()[0].setSource(this.getDefautSourceMap());
    }

    public setSatelliteSource() {
        this.olMap.getLayers().getArray()[0].setSource(this.getSatteliteSourceMap());
    }

    private getMinZoom(targetId)
    {
        const viewport: any = document.getElementById(targetId);
        const width = viewport.clientWidth;
        return Math.ceil(Math.LOG2E * Math.log(width / 80));
    }

    private getInitialMaxZoom(targetId)
    {
        const viewport: any = document.getElementById(targetId);

        if (!viewport)
          return;

        const width = viewport.clientWidth;

        return Math.ceil(Math.LOG2E * Math.log(width / 30));
    }

    public createImageMarker(data:any, asset:string, coord, color:string, scale:number)
    {
        var imageMarker = new Feature({
            geometry: new Point(fromLonLat(coord)),
            data: data
        });

        imageMarker.setStyle(new Style({
            image: new Icon(({
                color: color,
                crossOrigin: 'anonymous',
                src: asset,
                scale: scale,
            }))
        }));

        return imageMarker;
    }

    public createLabelMarker(label:any, coord, color:string)
    {
        var labelMarker = new Feature({
            geometry: new Point(fromLonLat(coord)),
            // data: data

        });

        labelMarker.setStyle(new Style({
            text: new Text(({
                text: label,
                font: '14px bold Calibri,sans-serif',
                color: color,
                crossOrigin: 'anonymous',
                fill: new Fill({
                    color: '#ffffff',
                    width: 0.5
                }),
                stroke: new Stroke({
                    color: '#000000',
                    width: 0.5
                })
            }))
        }));

        return labelMarker;
    }

    public getView(): View {
        return this.olMap.getView();
    }

    public getZoomLevel(): number {
        const view = this.getView();
        return view.getZoom();
    }

    public createPopupOverlay(container, options)
    {
        return new Overlay({
            element: container,
            autoPan: options.hasOwnProperty("autoPan")? options.autoPan :true,
            autoPanAnimation: {
                duration: options.hasOwnProperty("duration")? options.duration :250,
            }
        });
    }

    public createVectorLayer(features)
    {
        return new VectorLayer({
            source: new VectorSource({
                features: features
            })
        });
    }

    public createKMLVectorLayer(url,style)
    {
        //console.log(url,style);
        return new VectorLayer({
            source: new VectorSource({
              url: url,
              format: new KML({
                extractStyles: false
              })
            }),
            style: new Style({
                stroke: new Stroke(style)
            })

        });


    }

    public createPoint(lon,lat)
    {
        return new Point(Projection.fromLonLat([parseFloat(lon), parseFloat(lat)]));
    }

    public createFeature(point, field, reg, label)
    {
        return new Feature({
            geometry: point,
            field: field,
            model: reg,
            label: label
        });
    }

    public createStyleIcon(style) {
        return new StyleIcon(style);
    }

    public removeVectorLayer(vectorLayer)
    {
        this.olMap.removeLayer(vectorLayer);
        return this;
    }

    public removeLayerById(layerId:string)
    {
        let layersToRemove = [];
        this.olMap.getLayers().forEach(function (layer) {
            if (layer.get('id') === layerId) {
                layersToRemove.push(layer);
            }
        });

        for(var i = 0; i < layersToRemove.length; i++) {
            this.olMap.removeLayer(layersToRemove[i]);
        }
    }

    public addDivOverlay(div)
    {
        this.olMap.addOverlay(div);
    }

    public createCircleLayer(coord) {

        let vectorSource = new VectorSource()
        let vectorLayer = new VectorLayer({
            source: vectorSource,
            id: 'circle'
        })

        var circle = new Style({
            image: new Icon({
                anchor: [0.5, 1.15],
                crossOrigin: 'anonymous',
                //src: 'http://f3.funceme.br:9000/assets/icon/map-marker.svg',
                src: 'https://cdn.funceme.br/assets/icon/map-marker.svg',
                scale: 0.07,
            })
        });

        var feature = new Feature(
            new Point([0, 0])
        );
        feature.setStyle(circle);
        vectorSource.addFeature(feature);

        let layer = {
            type: 'Point',
            vector: vectorLayer
        };

        layer.vector.setZIndex(3);
        feature.getGeometry().setCoordinates(coord);

        return layer;
    }

    //public addEvents(events)
    public addEvents()
    {
        var _map = this.olMap;
        var _popup = this.popup;
        var _coord = this.coord;
        var _popupOverlay = this.popupOverlay;

        //if (events && events.hasOwnProperty('on') && events.on.hasOwnProperty('pointermove') && typeof events.on.pointermove == 'function') {
            this.olMap.on("pointermove", function (e) {
                const element = _popup.nativeElement;
                const coordElement = _coord.nativeElement;

                let features = _map.getFeaturesAtPixel(e.pixel);
                let clicado = false;

                let mousePositionArr = transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
                coordElement.innerHTML = mousePositionArr[0].toFixed(3)+', '+mousePositionArr[1].toFixed(3)
                if (!features) {
                    element.hidden = true;
                    return
                };
                features.forEach(feature => {
                    if (clicado) return;
                    if (feature && feature.values_.model ){
                        element.innerHTML = (feature
                            && feature
                            && feature.values_
                            && feature.values_.model
                            && feature.values_.model.nome)? feature.values_.model.nome : '';
                        element.hidden = false;
                        _popupOverlay.setPosition(e.coordinate);
                        clicado = true;
                    } else {
                        element.innerHTML = '';
                        element.hidden = true;
                    }
                });
            });
        //}
    }
}
