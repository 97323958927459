import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'libs/core-app/src/lib/services/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVars } from '../../shared/global';
import { MapCoreComponent } from '../../shared/components/map/map-core/map-core.component';
import Map from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector.js';
import KML from 'ol/format/KML.js';
import { Icon, Stroke, Style } from 'ol/style.js';
import View from 'ol/View';
import {fromLonLat, transform } from 'ol/proj.js';
import {defaults as defaultControls, Attribution} from 'ol/control.js';

@Component({
  selector: 'app-navigationsaf',
  templateUrl: './navigationsaf.component.html',
  styleUrls: ['./navigationsaf.component.css']
})
export class NavigationSafComponent extends MapCoreComponent implements OnInit {

    logged_user = '';
    logged_user_id = null;
    token;
    authForm: FormGroup;
    FortalezaStatus: boolean;
    QuixeramobimStatus: boolean;
    FortalezaMsg: string;
    QuixeramobimMsg: string;
    highlightRadar: string = null;
    olMap: any;
    isRadarFortalezaSelected: boolean = false;
    isRadarQuixeramobimSelected: boolean = false;
    isMACRORREGIOESSelected: boolean = false;
    isREGIOES_HIDROSelected: boolean = false;
    isMUNICIPIOSSelected: boolean = false;
    isBAIRROS_FORSelected: boolean = false;
    isSERSelected: boolean = false;
    isRADAR_FORTALEZASelected: boolean = false;
    isRADAR_QUIXERAMOBIMSelected: boolean = false;
    isAREAS_RISCO_FORTALEZASelected: boolean = false;
    prsfRMT0100DSHighlight: boolean = false;
    acumhorarioRMT0100DSHighlight: boolean = false;
    acumdiarioRMT0100DSHighlight: boolean = false;
    prsfDWSR92XHighlight: boolean = false;
    acumhorarioDWSR92XHighlight: boolean = false;
    acumdiarioDWSR92XHighlight: boolean = false;

    @ViewChild('map', { static: true }) map: MapCoreComponent;
    constructor(
        private router: Router,
        private auth: AuthService,
        private http:HttpClient,
        private formBuilder: FormBuilder,
        private activatedroute: ActivatedRoute

    ) {
        //super();
        super(router, activatedroute);
    }

    ngOnInit()
    {
        if (localStorage.getItem('user_name') != 'null') {
            this.logged_user = localStorage.getItem('user_name');
            this.logged_user_id = localStorage.getItem('user_id');
        } else {
            this.logged_user = 'Visitante';
            this.logged_user_id = null;
        }
        this.token = localStorage.getItem('app_user|access_token');

    }

    logout()
    {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    login()
    {
        this.router.navigate(['/auth']);
    }

    async linkRadar(radar: string, produto: string) {

        GlobalVars.currentRadar = radar;
        GlobalVars.currentProduct = produto;
        this.ativarRadar(radar);
        this.ativarProduto(produto, radar);
        await this.router.navigate([GlobalVars.getSafRoute()]);
    }

    async linkCamada(camada: string) {
        GlobalVars.currentLayer = camada;
        this.ativarCamada(camada);
        await this.router.navigate([GlobalVars.getSafRoute()]);
    }

    ngAfterViewInit(): void {

    }

    ngAfterViewChecked(): void{
        this.ativarCamada(GlobalVars.currentLayer)
        this.ativarProduto(GlobalVars.currentProduct, GlobalVars.currentRadar)
        if(GlobalVars.currentRadar === 'DWSR92X'){
            this.isRadarFortalezaSelected = true;
            this.isRadarQuixeramobimSelected = false;}
        else if(GlobalVars.currentRadar === 'RMT0100DS'){
            this.isRadarFortalezaSelected = false;
            this.isRadarQuixeramobimSelected = true;
        }
    }
    async ativarCamada(camada: string) {
        const propriedade = `is${camada}Selected`;
        for (const key in this) {
          if (key.startsWith("is") && key.endsWith("Selected")) {
            (this as any)[key] = false;
          }
        }
        if (propriedade in this) {
          (this as any)[propriedade] = true;
        }
      }

    async ativarProduto(produto: string, radar: string) {
        const produtoFormatado = produto.replace("-", "")
      const propriedade = `${produtoFormatado}${radar}Highlight`;
      for (const key in this) {
        if (key.endsWith("Highlight")) {
          (this as any)[key] = false;
        }
      }
      if (propriedade in this) {
        (this as any)[propriedade] = true;
      }
    }
      
    async ativarRadar(radar: string){
        if(radar === 'DWSR92X'){
            var camada = GlobalVars.currentLayer
            this.isRadarFortalezaSelected = true;
            this.isRadarQuixeramobimSelected = false;
            if (camada === "RADAR_QUIXERAMOBIM"){
                GlobalVars.currentLayer = "RADAR_FORTALEZA"
            }
        }else if(radar === 'RMT0100DS'){
            var camada = GlobalVars.currentLayer
            this.isRadarFortalezaSelected = false;
            this.isRadarQuixeramobimSelected = true;
            if (camada === "RADAR_FORTALEZA"){
                GlobalVars.currentLayer = "RADAR_QUIXERAMOBIM"
            }
        }
    }

}
